import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
const adminRoot = environment.adminRoot;

export interface IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}

const data: IMenuItem[] = [
  // {
  //   icon: 'iconsminds-shop-4',
  //   label: 'menu.dashboards',
  //   to: `${adminRoot}/dashboards`,
  //   // roles: [UserRole.Admin, UserRole.Editor],
  //   subs: [
  //     {
  //       icon: 'simple-icon-briefcase',
  //       label: 'menu.default',
  //       to: `${adminRoot}/dashboards/default`,
  //       // roles: [UserRole.Admin],
  //     },
  //     {
  //       icon: 'simple-icon-briefcase',
  //       label: 'menu.ems',
  //       to: `${adminRoot}/dashboards/edms`,
  //       // roles: [UserRole.Admin],
  //     },
  //   ],
  // },

  {
    icon: 'iconsminds-shop-4',
    label: 'menu.dashboards',
    to: `${adminRoot}/dashboards`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-briefcase',
        label: 'Dashboards',
        to: `${adminRoot}/dashboards`,
        // roles: [UserRole.Admin, UserRole.Editor],
        subs: [
          {
            icon: 'iconsminds-male-2',
            label: 'Default',
            to: `${adminRoot}/dashboards/default`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-male-2',
            label: 'EDMS',
            to: `${adminRoot}/dashboards/edms`,
            // roles: [UserRole.Admin],
          },
        ],
      }
    ],
  },


  {
    icon: 'simple-icon-chart',
    label: 'EMS',
    to: `${adminRoot}/ems`,
    // roles: [UserRole.Admin, UserRole.Editor],
    subs: [
      {
        icon: 'simple-icon-chart',
        label: 'Management',
        to: `${adminRoot}/ems`,
        // roles: [UserRole.Admin, UserRole.Editor],
        subs: [
          {
            icon: 'iconsminds-male-2',
            label: 'Voter Parchi',
            to: `${adminRoot}/ems/voterParchi`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-male-2',
            label: 'Polling Center',
            to: `${adminRoot}/ems/pollingCenter`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-male-2',
            label: 'Polling Station',
            to: `${adminRoot}/ems/pollingStation`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-male-2',
            label: 'Voter Parchi (Image)',
            to: `${adminRoot}/ems/voterParchiImage`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-male-2',
            label: 'Political Party',
            to: `${adminRoot}/ems/politicalParty`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-male-2',
            label: 'Voter List',
            to: `${adminRoot}/ems/voterList`,
            // roles: [UserRole.Admin],
          },
          {
            icon: 'iconsminds-male-2',
            label: 'Voter Marking',
            to: `${adminRoot}/ems/voterMarking`,
            // roles: [UserRole.Admin],
          },
          // {
          //   icon: 'iconsminds-male-2',
          //   label: 'Polling Station Detail',
          //   to: `${adminRoot}/ems/pollingDetail`,
          //   // roles: [UserRole.Admin],
          // },
        ],
      }
    ],
  },

];
export default data;
